/*
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com
Twitter: @rich_clark
*/

@font-face {
  font-family: 'Brush Script MT';
  src: url('../assets/fonts/Brush Script.ttf');
}

* {
  -webkit-appearance: none;
}

html {
  scroll-padding-top: 56px;
}

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent;
  overflow-wrap: break-word;
  word-break: break-all;
  font-size: 100%;
  font-family: "Hiragino Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  line-height: 1;
  padding-bottom: env(safe-area-inset-bottom);
  scroll-behavior: smooth;
  max-width: 640px;
  margin: 0 auto !important;
  color: #3C424A;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

nav ul,
li {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input,
select {
  vertical-align: middle;
}

button {
  padding: 0px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-contacts-auto-fill-button,
input::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  right: 0;
}

/* FIXME: tailwind.config.jsにて下記の書式でキーフレームを定義できるはずだが、上手く動いていないので一旦ベタ書き
module.exports= {
  theme: {
    extend: {
      keyframes: {
       wiggle: {
          '0%, 100%': {
           transform: 'rotate(-3deg)'
         },           ,
         '50%': {
           transform: 'rotate(3deg)'
         }
        }
      }
    }
  }
} */
@keyframes reflection {
  0% {
    transform: scale(0) rotate(45deg);
    opacity: 0;
  }

  50% {
    transform: scale(0) rotate(45deg);
    opacity: 0.5;
  }

  71% {
    transform: scale(4) rotate(45deg);
    opacity: 1;
  }

  100% {
    transform: scale(50) rotate(45deg);
    opacity: 0;
  }
}

@keyframes slideInUp {
  from {
    transform: translateY(100%);
  }
}

@keyframes slideInDown {
  from {
    transform: translateY(-100%);
  }
}

@keyframes slideOutUp {
  to {
    transform: translateY(-100%);
  }
}

@keyframes slideOutDown {
  to {
    transform: translateY(100%);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(-30px);
  }

  100% {
    transform: translateY(0px);
  }
}

@keyframes bounce-tl {
  0% {
    transform: rotate(-15deg) translateY(0px);
  }

  50% {
    transform: rotate(-15deg) translateY(-15px);
  }

  100% {
    transform: rotate(-15deg) translateY(0px);
  }
}

@keyframes ripple {
  0% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 0;
  }

  60% {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(1.6, 1.6);
    transform: scale(1.6, 1.6);
    opacity: 0;
  }
}

@keyframes zoomIn {
  from {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes infinity-scroll-left {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}

@keyframes infinity-scroll-right {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}
